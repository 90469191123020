ion-title {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 22px;
}

ion-header {
    margin-left: 0;
    margin-bottom: 0;
}

ion-buttons {
    margin: 0;
    padding-left: 20px;
    padding-bottom: 22px;
}
