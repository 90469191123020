ion-header {
    text-align: center;
}

ion-content {
    background: var(--ion-color-green);
    background-size: cover;
}

.height-auto {
    height: auto !important;
}

ion-header ion-title {
    color: #4a4a4a;
    font-size: 1.5rem;
    font-weight: 400;
}

ion-back-button {
    --color: var(--ion-color-green);
}

#resort-page ion-title,
#manage-reservation-page ion-title {
    font-size: 1.25rem;
    text-transform: capitalize;
}
