.gallery-modal--close {
    left: 0 !important;
}

.gallery-modal--close button svg {
    fill: var(--ion-color-green) !important;
}

.gallery-figcaption--thumbnails {
    padding-left: 1rem;
    padding-right: 1rem;
}
