@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Work_Sans/static/WorkSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('/assets/fonts/AvenirLTStd-Roman.otf') format('opentype');
    font-weight: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('/assets/fonts/AvenirLTStd-Medium.otf') format('opentype');
    font-weight: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
