ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
}

ion-menu ion-list {
    padding: 0;
}

ion-menu ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    --min-height: 60px;
    border-radius: 4px;
    border-bottom: 1px solid var(--ion-color-gold);
    font-size: 24px;
}

ion-menu ion-item ion-label {
    padding-left: 20px;
}

.ionicon .s-ion-icon {
    display: none;
}

.ion-color-teal {
    --ion-color-base: var(--ion-color-teal);
    --ion-color-base-rgb: var(--ion-color-teal-rgb);
    --ion-color-contrast: var(--ion-color-teal-contrast);
    --ion-color-contrast-rgb: var(--ion-color-teal-contrast-rgb);
    --ion-color-shade: var(--ion-color-teal-shade);
    --ion-color-tint: var(--ion-color-teal-tint);
}

.menu-logo-container {
    margin: 0 auto;
    padding: 45px 0 45px 10px;
    width: 250px;
}

.solay-logo-name {
    width: 104px;
    height: 42px;
}

.solay-logo {
    width: 42px;
    height: 42px;
    margin-right: 22px;
}
