#page-profile ion-item {
    width: 100%;
    --background: transparent;
    --border-color: transparent;
    --background-activated: transparent;
    --highlight-color-focused: none;
}

ion-chip {
    border-radius: 50px;
    height: auto;
}

ion-avatar {
    width: 100px;
    height: 100px;
}

.btn-position {
    font-size: 1.2rem;
    color: var(--ion-color-green);
    border: none;
    background: transparent;
    outline: none;
}

.button {
    font-size: inherit !important;
}
