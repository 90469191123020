#reservations-page ion-content {
    --background: #fff;
    padding: 10px;
}

ion-title {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    padding: 0;
}

ion-header {
    margin-left: 0;
    margin-bottom: 0;

    border-bottom: 0;
}

ion-toolbar {
    border-bottom: 1px solid var(--ion-color-gold);
}

ion-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.reservationsToolbar {
    padding-top: 18px !important;
}

#reservations-page .reservations-container {
    padding: 10px;
}

.no-reservations-found {
    text-align: center;
    padding: 10px;
}
