.date-input {
    height: 48px;
    border-radius: 24px;
    border: 1px solid #d3d3d3;
    background-color: var(--ion-color-gold);
    margin-bottom: 30px;
    color: #4a4a4a;
    font-weight: 500;
    font-size: 18px;
    padding-left: 20px !important;
}

.datetime-container {
    margin-bottom: 30px;
    padding-left: 16px;
    padding-right: 16px;
}

.item-pickers {
    margin-bottom: 30px;
}

.item-qty-input {
    height: 48px;
    border-radius: 24px;
    border: 1px solid #d3d3d3;
    background-color: var(--ion-color-gold);
}

.reservation-schedule-unselected {
    width: 104px;
    height: 32px;
    border-radius: 16px;
    --background: #f4f4f4;
    color: #4a4a4a;
    font-weight: 400;
}

.reservation-schedule-selected {
    width: 104px;
    height: 32px;
    border-radius: 16px;
    --background: #f0bb49;
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 400;
}

.picker-title-separator {
    color: #939393;
}

.qty-picker {
    box-shadow: 0 9px 32px -12px rgba(0, 0, 0, 0.18);
    border: 1px solid var(--ion-color-gold);
    background-color: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 15px;
}

.qty-picker-input {
    width: 30px;
    text-align: center;
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.72px;
}

.qty-picker-input-minus-button {
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 0;
    --background: #ffffff;
    color: #939393;
}

.cancel-button {
    width: 40px;
    height: 40px;
    border: 2px solid var(--ion-color-gold);
    --background: #ffffff;
    color: #000000;
    border-radius: 50%;
}

.options-label {
    text-align: left;
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 400;
}

.list-action {
    padding-bottom: 0;
}

.list-action ion-item {
    cursor: pointer;
    --padding-start: 1.5rem;
    --padding-end: 0.5rem;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--ion-color-gold);
}

.list-action ion-item:first-child {
    border-top: 1px solid var(--ion-color-gold);
}

.list-action ion-img {
    width: 40px;
    height: 40px;
}

.chair-cabana-price {
    font-size: 24px;
    font-weight: normal;
    color: black;
}

.native-input.sc-ion-input-ios {
    color: black !important;
}
