.payment-form-modal {
    --background: #dddddd;
}
/**
 * Basic styling from https://stripe.com/docs/stripe-js/react#customization-and-styling
 */
.FormGroup {
    margin: 15px 20px;
    padding: 0;
    will-change: opacity, transform;
    background: #fff;
    border-radius: 4px;
    border: 1px solid lightblue;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}
