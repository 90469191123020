#page-login ion-content {
    --background: none;
    background: var(--ion-color-green) url('/assets/mocks/bg-login.jpg');
    background-size: cover;
}

.form-stacked {
    padding: 2rem;
    width: 100%;
}

.input-icon {
    --background: #fff;
    border: 2px solid #e6e6e6;
    border-radius: 23px;
    text-indent: 1rem;
    color: #4a4a4a;
    font-size: 1.125rem;
    text-align: left;
    margin-bottom: 1.5rem;
}

.input-icon .icon-outer {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    text-align: center;
}

.vh50 {
    height: 50vh;
}

.footer-info {
    color: var(--ion-color-light);
    text-transform: lowercase;
}

.signup-link {
    color: var(--ion-color-light);
    font-weight: bold;
    text-decoration: underline;
    text-transform: lowercase;
}
