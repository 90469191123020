#reservation-form {
    padding: 0 1.5rem 1.5rem 1.5rem;
    background: #ffffff;
}

#order-form {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.button-group {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
}
