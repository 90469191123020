.location-card {
    width: 100%;
}

/* Allow child <ion-select> to display above other content */
ion-item {
    overflow: visible;
}

ion-select {
    max-width: 100%;
    margin: 0 auto 0 auto;
    border: 1px solid var(--ion-color-green);
    background: #ffffff;
    color: #4a4a4a;
    transform: translateY(-50%);
    --padding-start: 15px;
    --padding-end: 15px;
}

ion-select .select-text {
    text-overflow: clip;
}

.select-icon .select-icon-inner {
    color: var(--ion-color-green) !important;
    background: var(--ion-color-green) !important;
}

.pool-selector .popover-content {
    left: 0;
    width: 100%;
}

.pool-selector .popover-content .sc-ion-select-popover {
    padding: 0 0.5rem;
}

.pool-selector .popover-content .select-interface-option {
    border: 1px solid var(--ion-color-gold);
    overflow: hidden;
    border-radius: 9999px;
    text-align: center;
    margin: 1rem 0;
}

.pool-selector .popover-content ion-radio {
    width: 0 !important;
    max-width: 0 !important;
    opacity: 0 !important;
}

.pool-selector .item-inner-highlight {
    display: none !important;
    border: 0 !important;
    background: none !important;
}
