html {
    background: var(--ion-overlay-background-color, var(--ion-color-step-100, #f9f9f9));
}

ion-content {
    --background: var(--ion-color-green);
    font-size: 1rem;
    font-weight: 500;
}

ion-item-divider {
    min-height: 1px;
}

/* change the bg using `<IonContent className="bg-water">` */
ion-content.bg-water {
    --background: none;
    background: var(--ion-color-green) url('/assets/bg-water.png');
    background-size: cover;
}

/* default bg */
ion-content.bg-wave {
    --background: none;
    background: var(--ion-color-green) url('/assets/wavey30.png');
    background-size: cover;
}

/** useful on IonCol */
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.item-around {
    height: 50%;
    margin-top: 0;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
}

ion-text.description {
    color: #939393;
    font-size: 0.8125rem;
    font-weight: 400;
}

.col-grow {
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
    align-items: center;
    display: flex;
}

.hidden {
    display: none;
}

.text-color-white {
    color: #fff !important;
}

.text-color-gold {
    color: var(--ion-color-gold);
}

.bold {
    font-weight: bold;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-16 {
    margin-top: 1rem !important;
}

.mt-32 {
    margin-top: 2rem !important;
}

.mt-64 {
    margin-top: 4rem !important;
}

.mt-80 {
    margin-top: 5rem !important;
}

.mb-6 {
    margin-bottom: 0.375rem !important;
}

.mb-8 {
    margin-bottom: 0.5rem !important;
}

.mb-12 {
    margin-bottom: 0.75rem !important;
}

.mb-16 {
    margin-bottom: 1rem !important;
}

.mb-24 {
    margin-bottom: 1.5rem !important;
}

.mb-32 {
    margin-bottom: 2rem !important;
}

.mb-80 {
    margin-bottom: 5rem !important;
}

.vh30 {
    height: 30vh;
}

.font-size-16 {
    font-size: 1rem;
}

.font-size-18 {
    font-size: 1.125rem;
}

.font-size-24 {
    font-size: 1.5rem;
}

.lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.max-w-full {
    max-width: 100%;
}

.max-h-full {
    max-height: 100%;
}

.border-t-gold {
    border-top: 1px solid var(--ion-color-gold);
}

.border-b-green {
    border-bottom: 1px solid #387d79;
}

.photo-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.photo-group ion-img {
    flex: auto;
    border-right: 14px solid #fff;
}

.photo-group ion-img:last-child {
    border-right: none;
}

.square-ul {
    list-style-type: square;
}

ion-button {
    text-transform: none;
    --border-radius: 24px;
    max-height: 45px;
}

ion-button.button-large {
    font-weight: 500;
    --border-radius: 24px;
}

.round {
    border-radius: 24px;
}

.ion-round {
    --border-radius: 24px;
}

.inputs ion-button {
    line-height: 1;
    width: 49px;
    height: 41px;
    padding: 0;
    margin: 0;
    --color: #fff;
    --box-shadow: 0 20px 39px -12px rgba(0, 0, 0, 0.28);
    --border-radius: 24px;
    --background: transparent;
}

ion-item.inputs {
    height: 45px;
    font-size: 1rem;
    box-shadow: 0 20px 39px -12px rgba(0, 0, 0, 0.28);
    border-radius: 24px;
    --background: #ffffff !important;
    --color: #4a4a4a !important;
    position: relative;
    text-align: left;
    text-indent: 1rem;
    --highlight-color-focused: none;
    margin-bottom: 2%;
    width: 100%;
    overflow: hidden;
}

.inputs ion-input {
    font-weight: 500 !important;
    --placeholder-color: #666;
}

.inputs ion-button ion-icon,
#label-component ion-button ion-icon {
    color: var(--ion-color-gold);
}

.main-button {
    --border-radius: 24px;
    min-height: 3rem !important;
    text-align: center;
    color: #ffffff !important;
    font-family: 'Work Sans' !important;
    font-size: 21px !important;
    font-weight: 500 !important;
    letter-spacing: 1.17px !important;
    line-height: 18px !important;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    margin-bottom: 32px;
}

ion-header {
    position: relative !important;
    z-index: 9999 !important;
}

/* Rounded alert variant (CSS component) */
ion-alert.alert--rounded {
    --backdrop-opacity: 0.625;
}

.alert--rounded .alert-wrapper {
    max-width: 100% !important;
    background: #ffffff !important;
    border: 1px solid var(--ion-color-gold) !important;
    border-radius: 9999px !important;
}

.alert--rounded .alert-wrapper .alert-message {
    font-family: 'Work Sans' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
}

ion-col {
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
}

.qty-picker-input-plus-button {
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 7px -2px rgba(0, 0, 0, 0.5);
    --background: var(--ion-color-gold);
    --background-activated: var(--ion-color-gold);
    --background-focused: var(--ion-color-gold);
    --background-hover: var(--ion-color-gold);
    color: white;
}

/* Large text alert variant (CSS component) */
.alert--large .alert-input-wrapper .alert-input {
    font-size: 17px !important;
}

/* Pulsing umbrella animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.333333);
    }
    100% {
        transform: scale(1);
    }
}

.animate-pulse > img {
    animation-name: pulse;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
