/* when content is blocked by the notch use this class */
.mt-safe,
/* gallery close button */
.gallery-modal--close {
    margin-top: var(--ion-safe-area-top) !important;
}

/* when content is blocked by the Home Indicator */
.mb-safe,
/* calendar OK button */
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    margin-bottom: var(--ion-safe-area-bottom) !important;
}

/* Tailwind CSS utility classes enhanced with Ionic's custom properties*/

html body .z-100 {
    z-index: 100 !important;
}

html body .overflow-hidden {
    overflow: hidden !important;
}

html body .hidden {
    display: none !important;
}

html body .sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
}

html body .flex-grow-0 {
    flex-grow: 0 !important;
}

html body .flex-grow {
    flex-grow: 1 !important;
}

html body .flex-shrink-0 {
    flex-shrink: 0 !important;
}

html body .flex-shrink {
    flex-shrink: 1 !important;
}

html body .flex-no-wrap {
    flex-wrap: nowrap !important;
}

html body .justify-start {
    justify-content: flex-start !important;
}

html body .justify-between {
    justify-content: space-between !important;
}

html body .justify-end {
    justify-content: flex-end !important;
}

html body .items-start {
    align-items: flex-start !important;
}

html body .items-between {
    align-items: space-between !important;
}

html body .items-end {
    align-items: flex-end !important;
}

html body .w-56 {
    width: 14rem !important;
}

html body .w-64 {
    width: 16rem !important;
}

html body .w-full {
    width: 100% !important;
}

html body .min-w-full {
    min-width: 100% !important;
}

html body .max-w-full {
    max-width: 100% !important;
}

html body .h-48 {
    height: 12rem !important;
}

html body .h-56 {
    height: 14rem !important;
}

html body .h-64 {
    height: 16rem !important;
}

html body .h-full {
    height: 100% !important;
}

html body .min-h-full {
    min-height: 100% !important;
}

html body .max-h-full {
    max-height: 100% !important;
}

html body .mt-6 {
    margin-top: 1.5rem !important;
}

html body .mt-8 {
    margin-top: 2rem !important;
}

html body .ml-1 {
    margin-left: 0.25rem !important;
}

html body .ml-4 {
    margin-left: 1rem !important;
}

html body .rounded-none {
    border-radius: 0px !important;
    --border-radius: 0px !important;
}

html body .rounded-full {
    border-radius: 9999px !important;
    --border-radius: 9999px !important;
}

html body .bg-white {
    background: #ffffff !important;
}

html body .font-light {
    font-weight: 400 !important;
}

html body .font-normal {
    font-weight: 400 !important;
}

html body .font-medium {
    font-weight: 500 !important;
}

html body .font-semibold {
    font-weight: 600 !important;
}

html body .font-bold {
    font-weight: 700 !important;
}

html body .text-black {
    color: #000000 !important;
    --color: #000000 !important;
    --ion-color-contrast: #000000 !important;
}

html body .text-white {
    color: #ffffff !important;
    --color: #ffffff !important;
    --ion-color-contrast: #ffffff !important;
}
html body .text-light {
    color: #d9d9d9 !important;
    --color: #d9d9d9 !important;
    --ion-color-contrast: #d9d9d9 !important;
}

html body .text-xs {
    font-size: 0.75rem !important;
}

html body .text-sm {
    font-size: 0.875rem !important;
}

html body .text-base {
    font-size: 1rem !important;
}

html body .text-left {
    text-align: left !important;
}

html body .text-center {
    text-align: center !important;
}

html body .underline {
    text-decoration: underline !important;
}

html body .uppercase {
    text-transform: uppercase !important;
}

html body .leading-normal {
    line-height: 1.5 !important;
}

html body .whitespace-no-wrap {
    white-space: nowrap !important;
}

html body .object-cover {
    object-fit: cover !important;
}

html body .object-center {
    object-position: center !important;
}

html body .color,
html body .color * {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

html body .grayscale,
html body .grayscale * {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
