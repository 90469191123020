#label-component {
    --background: white !important;
    --color: #4a4a4a !important;
    position: relative;
    text-align: left;
    text-indent: 1rem;
    width: 100%;
    height: 45px;
    font-size: 1rem;
    box-shadow: 0 20px 39px -12px rgba(0, 0, 0, 0.28);
    border-radius: 24px;
}

#label-component ion-button {
    margin: 0;
    padding: 0;
}
