.container {
    text-align: center;
    padding: 24px 24px 38px 16px;
}

.container strong {
    font-size: 20px;
    line-height: 26px;
}

.container p,
.container li {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}

.container a {
    text-decoration: none;
    color: var(--ion-color-gold);
}
