.description-modal {
    --color: #fff;
    --background: none;
    background: var(--ion-color-green);
    background-size: cover;
}

.description-modal ion-icon {
    font-size: 25px;
}

ion-item ion-img {
    border: 2px solid var(--ion-color-gold);
    border-radius: 50%;
}
