.toc {
    color: white;
    --background: transparent;
}

.toc-checkbox {
    margin-right: 5px;
    --border-color: var(--ion-color-gold);
}

a {
    color: var(--ion-color-gold);
}

li {
    margin: 1rem 0 1rem 0;
}

.toc-container {
    --color: #fff;
    --background: none;
    background: var(--ion-color-green);
    background-size: cover;
}

.toc-text {
    height: 100%;
    overflow-y: scroll;
}

.accept-toc-btn {
    color: white;
    text-transform: uppercase;
}

ion-title {
    text-transform: none !important;
    font-size: 20px !important;
}

.in-toolbar ion-button {
    font-size: 16px !important;
    color: var(--ion-color-teal) !important;
}
