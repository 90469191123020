.guest-booking-list {
    font-weight: 500;
    text-align: left;
    justify-content: unset;
    display: block;
    align-items: unset;
}

.guest-booking-list-item-contact a {
    color: #555555;
    text-decoration: none;
}

.guest-booking-list-item-props {
    color: black;
}

.guest-booking-icon {
    font-size: 35px;
}

.guest-booking-icon-cancel {
    color: #a63a38;
}

.guest-booking-icon-contact {
    color: #818081;
}

.ion-color-bookings-list-heading {
    font-weight: 500;
    line-height: 1;
}
