ion-card {
    margin: 0 0 2rem 0;
    font-weight: 500;
    --color: #4a4a4a;
    --ion-item-background: #fff;
    border-radius: 8px;
    box-shadow: 0 14px 32px -12px rgba(0, 0, 0, 0.33);
    max-width: 500px;
    margin: 0 auto;
}

ion-card-content {
    padding: 0 !important;
}

ion-card-title {
    font-size: 1.313rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

ion-card-subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 1.125rem;
    --color: #9b9b9b;
}
