/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-font-family: 'Work Sans';

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-text-color: #666;

    /** Teal **/
    --ion-color-teal: #387d79;
    --ion-color-teal-rgb: 105, 187, 123;
    --ion-color-teal-contrast: #ffffff;
    --ion-color-teal-contrast-rgb: 255, 255, 255;
    --ion-color-teal-shade: #387d79;
    --ion-color-teal-tint: #387d79;

    /** Custom Gold **/
    --ion-color-gold: var(--ion-color-gold);
    --ion-color-gold-rgb: 248, 184, 43;
    --ion-color-gold-contrast: #ffffff;
    --ion-color-gold-contrast-rgb: 255, 255, 255;
    --ion-color-gold-shade: #daa226;
    --ion-color-gold-tint: #f9bf40;

    /** Custom Green **/
    --ion-color-green: #2c6a65;
    --ion-color-green-rgb: 44, 106, 101;
    --ion-color-green-contrast: #ffffff;
    --ion-color-green-contrast-rgb: 255, 255, 255;
    --ion-color-green-shade: #275d59;
    --ion-color-green-tint: #417974;

    /** Custom Grey **/
    --ion-color-grey: #f4f4f4;
    --ion-color-grey-rgb: 244, 244, 244;
    --ion-color-grey-contrast: #000000;
    --ion-color-grey-contrast-rgb: 0, 0, 0;
    --ion-color-grey-shade: #d7d7d7;
    --ion-color-grey-tint: #f5f5f5;

    /** Custom disabled **/
    --ion-color-disabled: #aaa;
    --ion-color-disabled-rgb: 170, 170, 170;
    --ion-color-disabled-contrast: #fff;
    --ion-color-disabled-contrast-rgb: 255, 255, 255;
    --ion-color-disabled-shade: #d7d7d7;
    --ion-color-disabled-tint: #f5f5f5;
}

.ion-color-gold {
    --ion-color-base: var(--ion-color-gold);
    --ion-color-base-rgb: var(--ion-color-gold-rgb);
    --ion-color-contrast: var(--ion-color-gold-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
    --ion-color-shade: var(--ion-color-gold-shade);
    --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-grey {
    --ion-color-base: var(--ion-color-grey);
    --ion-color-base-rgb: var(--ion-color-grey-rgb);
    --ion-color-contrast: var(--ion-color-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-grey-shade);
    --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-disabled {
    --ion-color-base: var(--ion-color-disabled);
    --ion-color-base-rgb: var(--ion-color-disabled-rgb);
    --ion-color-contrast: var(--ion-color-disabled-contrast);
    --ion-color-contrast-rgb: var(--ion-color-disabled-contrast-rgb);
    --ion-color-shade: var(--ion-color-disabled-shade);
    --ion-color-tint: var(--ion-color-disabled-tint);
}
