ion-checkbox {
    --background-checked: var(--ion-color-gold);
    --border-color: white;
    --border-color-checked: var(--ion-color-gold);
    --background: transparent;
    --size: 23px;
}

.form-stacked {
    padding: 2rem;
    width: 100%;
}

.ion-item-checkbox {
    --background: none;
    --border-style: none;
    --min-height: 10px;
}

.terms-checkbox {
    text-align: left;
    margin-left: 0.625rem;
}

.tos-space {
    margin-right: 25%;
}
ion-button[type='submit'] {
    height: 45px;
}
