.reservation-status-button {
    min-height: 3rem !important;
    text-align: center;
    color: #ffffff !important;
    font-size: 21px !important;
    font-weight: 500 !important;
    letter-spacing: 1.17px !important;
    line-height: 18px !important;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.reservation-card {
    box-shadow: 0 14px 32px -12px rgba(0, 0, 0, 0.33);
    border-radius: 8px;
    background-color: #ffffff;
}

.reservation-card ion-card-title {
    color: #30282a;
    font-size: 21px;
    font-weight: 400;
    font-size: 20px;
}

.reservation-card ion-card-subtitle {
    color: #939393;
    font-size: 18px;
    font-weight: 300;
}

ion-card-subtitle {
    text-transform: lowercase;
    font-size: 1rem;
}

ion-card-header {
    padding: 20px 20px 0px;
}
