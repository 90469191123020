.resort-area-map {
    position: relative;
    z-index: 0;
}

.resort-area-map > img {
    position: relative;
    z-index: 10;
    display: block;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

.resort-area-map table {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.resort-area-map table tbody {
    display: table-row-group;
    position: static;
}

.resort-area-map table tr {
    display: table-row;
    position: static;
}

.resort-area-map table td {
    display: table-cell;
    position: static;
}

.resort-area-map table div {
    position: relative;
    z-index: 30;
    height: 0;
    padding-top: 100%;
}

.resort-area-map table div div {
    all: unset;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    user-select: none;
}

.resort-area-map table div div img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center center;
}

.resort-area-map table div div span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
    text-shadow: -1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px -1px 0 #ffffff, 1px 1px 0 #ffffff;
}
