#message-page ion-content {
    width: 100%;
    height: 100%;
    --background: white !important;
}

.no-message-found {
    text-align: center;
    padding: 10px;
}
