#messages-content {
    background: #fff;
}

.chat-footer {
    bottom: 0;
    height: 46px;
    width: 100%;
}

#messageItem {
    border-top: 1px solid gray;
}

.sendMessageInput {
    border: 0;
    width: 100%;
    color: #9b9b9b;
    font-family: 'Avenir';
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2.08px;
}

.iconMessage {
    width: 24px;
    height: 24px;
}
