:root {
    --ion-color-bookings-list-heading: #f3b84c;
    --ion-color-bookings-list-heading-text: #ffff;
}

.ion-color-bookings-list-heading {
    --ion-color-base: var(--ion-color-bookings-list-heading);
}
.ion-color-bookings-list-heading-text {
    --ion-color-base: var(--ion-color-bookings-list-heading-text);
}
