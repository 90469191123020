#page-search ion-content {
    overflow: hidden;
}

#page-search ion-grid {
    --ion-grid-padding: 0;
}

#content-search {
    padding: 1rem 3rem 3rem 3rem;
}

#search-intro {
    text-align: center;
}

#search-input {
    margin-top: 2rem;
}

#search-input ion-item ion-input ion-button {
    margin-left: 7px;
}

.search-container {
    padding: 0;
    width: 100%;
}

#search-results {
    width: 100%;
    min-height: 100vh;
    display: block;
    padding: 0.5rem;
    /* overflow-y: auto; */
    background-color: #fff;
    flex-direction: column;
    box-shadow: inset 0 5px 5px -5px #333;
}

.no-found {
    padding: 0px 13%;
}

.search-button {
    position: absolute;
    right: 3px;
}
