.message-border {
    border-bottom: 1px solid var(--ion-color-gold);
    width: 100%;
}

.messagesContainer {
    display: flex;
    margin-top: 5%;
    padding-left: 5%;
}

.messageText {
    padding-left: 19%;
    margin: 0px 0px 10px 0px;
}

.box-1 {
    flex: 1;
}

.name {
    margin: 7px 0px 0px 5px;
}

.date {
    margin-top: 7px;
    color: gainsboro;
    text-align: end;
    padding-right: 10%;
}

.messageItem {
    --padding-start: 0px;
    --inner-padding-end: 0px;
}
