ion-title {
    color: #4a4a4a;
    font-family: 'Work Sans';
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    padding: 0;
}

ion-header {
    margin-left: 0;
    margin-bottom: 0;

    border-bottom: 0;
}

ion-toolbar {
    border-bottom: 1px solid var(--ion-color-gold);
}

ion-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

ion-avatar {
    width: 42px;
    height: 42px;
}

#messages-content {
    --offset-bottom: -46px !important;
}

.messagesToolbar {
    padding-top: 18px !important;
}

.chat-scroll {
    width: 100%;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.fixed[scrollx='true'],
div[scrolly='true'] {
    position: relative;
    overflow: hidden;
}

.fixed[scrolly='true'] {
    overflow-y: auto;
}

.msg-type-1 {
    font-family: 'Avenir';
    font-weight: 400;
    padding: 19px 17px;
    letter-spacing: 1.1px;
    text-align: left;
    border-radius: 20px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

.msg-type-2 {
    font-family: 'Avenir';
    font-weight: 400;
    border-radius: 20px;
    border: 1px solid var(--ion-color-gold);
    background-color: var(--ion-color-gold);
    padding: 19px 25px;
    text-align: left;
}

.message-container {
    margin: 5px;
}

.image-container {
    padding: 0 5px 5px 5px;
    border: 1px dashed var(--ion-color-gold);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0.8px;
    font-style: italic;
    font-weight: bold;
    display: block;
    background-color: white;
    color: #013255;
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px;
    margin: 5px;
}

.msgTimestamp {
    color: #9b9b9b;
    font-family: 'Avenir';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.17px;
    text-align: right;
}

.msgs {
    margin-bottom: 46px;
}
