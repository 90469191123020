.MuiInput-root {
    font-weight: 500;
    line-height: 1 !important;
    color: #4a4a4a !important;
    font-size: 1.125rem !important;
    height: 100% !important;
}

.MuiFormControl-root {
    display: block !important;
    height: 100% !important;
}

.MuiInput-underline::before,
.MuiInput-underline::after {
    display: none !important;
}

.MuiInputBase-input {
    padding: 0 !important;
    cursor: pointer !important;
}

.MuiDialog-root .MuiPickersDatePickerRoot-toolbar {
    padding-right: 2rem;
    padding-left: 2rem;
}

.MuiDialog-root .MuiPickersToolbar-toolbar {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--ion-color-gold);
}

.MuiDialog-root .MuiPickersModal-dialogRoot {
    min-width: 100%;
    position: fixed;
    bottom: 0;
    margin: 0;
    border-radius: 0;
}

.MuiDialog-root .MuiPickersBasePicker-pickerView {
    max-width: 100%;
}

.MuiDialog-root .MuiPickersCalendarHeader-switchHeader {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.MuiDialog-root .MuiDialog-paperWidthSm {
    max-width: 100%;
}

.MuiDialog-root .MuiPickersDay-daySelected:hover,
.MuiDialog-root .MuiPickersDay-daySelected {
    background-color: var(--ion-color-gold);
}

.MuiDialog-root .MuiButton-textPrimary {
    padding: 0 1rem;
    text-align: center;
    color: var(--ion-color-gold);
    border: 1px solid var(--ion-color-gold);
    border-radius: 24px;
    vertical-align: middle;
}

.MuiDialog-root .MuiButton-textPrimary:first-child {
    border: none !important;
}

.MuiButton-textPrimary .MuiButton-label {
    line-height: 28px !important;
}

.calendar-btn {
    height: 45px;
}
